<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        While it is far more soluble than its iron(III) counterpart, iron (II) hydroxide,
        <chemical-latex content="Fe(OH)2," />
        is still a rather sparingly soluble solid. The
        <stemble-latex :content="'$\\text{K}_{\\text{sp}}$'" />
        value of iron(II) hydroxide is
        <stemble-latex :content="'$4.87\\times 10^{-17}.$'" />
      </p>

      <p class="mb-3">
        a) Determine the solubility of the salt in terms of molarity,
        <stemble-latex :content="'$\\text{mol/L or M.}$'" />
      </p>

      <calculation-input
        v-model="inputs.molarSolubility"
        :prepend-text="'$\\text{S}$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />
      <br />

      <p class="mb-3">
        b) What is the solubility of the salt in terms of
        <stemble-latex :content="'$\\text{g/L?}$'" />
      </p>

      <calculation-input
        v-model="inputs.gramSolubility"
        :append-text="'$\\text{g/L}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question128',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        molarSolubility: null,
        gramSolubility: null,
      },
    };
  },
};
</script>
